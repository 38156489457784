<template>
<div>
    <form class="col-md-12">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#c5dadf;"> 
                <div class="form-horizontal cascde-forms">
                <h1 class="page-header">EMI Details</h1>
                    <div class="form-group row CHK" >
                        <label class=" col-lg-4 col-md-3 control-label">
                         Enter Receipt No.<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                        </label>
                        <div class="col-lg-6 col-md-9">
                          <input class="form-control form-cascade-control input-small"  v-model="form.receiptno" data-val="true" data-val-required="Required" id="" name="" type="number" value="" required>
                         </div>
                    </div>
                    <div class="form-group row CHK" >
                        <label class=" col-lg-4 col-md-3 control-label">
                         Enter Receive Amount<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                        </label>
                        <div class="col-lg-6 col-md-9">
                          <input class="form-control form-cascade-control input-small"  v-model="form.amount" data-val="true" data-val-required="Required" id="" name="" type="number" value="" required>
                         </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label">Received By<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select class="form-control form-cascade-control input-small" style="width:50%" v-model="form.collectedbyid">
                                <option v-for="(item) in employees" v-bind:key="item.id" :value="item.id">{{ item.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                          <label class="col-lg-4 col-md-3 control-label">Pay Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                          <div class="col-lg-6 col-md-9">
                            <!-- <input class="form-control form-cascade-control input-small"  v-model="form.receiveddate" id="" name=""  type="date" value="" required> -->
                            <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="form.receiveddate">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <button type="button"
                                            class="date-picker"
                                            @click="togglePopover()">
                                            <i class='bx bx-calendar'></i>
                                        </button>
                                        <input :value="inputValue" @click="togglePopover()"
                                            class="form-control form-cascade-control input-small"
                                            placeholder="DD/MM/YYYY" readonly />
                                    </div>
                                </template>
                            </vc-date-picker>
                          </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label">Mode of Payment<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <select class="form-control form-cascade-control input-small" v-model="form.mode" >
                                <option v-for="item in registers" :key="item.id" :value="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row CHK" >
                        <label class=" col-lg-4 col-md-3 control-label">
                         Cheque/ Draft No./Transaction No.<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                        </label>
                        <div class="col-lg-6 col-md-9">
                          <input class="form-control form-cascade-control input-small"  v-model="form.refno">
                         </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label">
                        Remark<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea class="form-control form-cascade-control input-small" v-model="form.remark" cols="20" rows="2" style="height:83px;"></textarea>
                        </div>
                    </div>
                        <div class="form-group row row leadbycustomer" style="display: none">
                          <div class="col-lg-6 col-md-9">
                            <span id="fill"></span>
                          </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-3"></div>
                        <div class="col-md-3">
                            <button type="button" v-if="!submitfalse" class="btn btn-primary" style="margin-top:0" @click="submit()">Submit</button>
                            <span v-if="submitfalse" class="spinner"></span>
                            <button type="button" class="btn btn-danger" @click="cancel()">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
export default {
     mixins:[Constants],
     props:['emiitem'],
     data(){
         return {
             form:new window.Form({
                id:0,receiptno:'',collectedbyid:'',amount:'',
                receiveddate:'',approval:0,
                mode:'',refno:'',
                remark:'',loanemiid:0,fy:"2022",paymenttype:1
            }),
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            submitfalse:false
         }
     },
     mounted(){
        this.$store.dispatch("fetchregisters")
        if(this.emiitem!=null){
            this.form.loanemiid = this.emiitem.id
        }
        this.$store.dispatch("fetchemployees")
        // console.log("Emi item")
        // console.log(emiitem)
     },
    computed:{
        ...mapGetters([
		      'employees','registers','edititem'
	    ])
    },
    methods:{
        submit(){
            if(this.form.receiptno==''){
                this.$notify({
                    text:'Receipt No. is Necessary',
                    type:'error',
                    duration:'8000',
                    speed:'800',
                    
                })
            }else if(this.form.amount=='' && this.form.amount==0){
                this.$notify({
                    text:'amount is Necessary',
                    type:'error',
                    duration:'8000',
                    speed:'800',
                    
                })
            }else if(this.form.collectedbyid==''){
                this.$notify({
                    text:'Received By is Necessary',
                    type:'error',
                    duration:'8000',
                    speed:'800',
                    
                })
            }else if(this.form.receiveddate==''){
                this.$notify({
                    text:'Pay Date is Necessary',
                    type:'error',
                    duration:'8000',
                    speed:'800',
                    
                })
            }else if(this.form.mode==''){
                this.$notify({
                    text:'Select Mode of Payment is Necessary',
                    type:'error',
                    duration:'8000',
                    speed:'800',
                    
                })
            }else if(this.form.amount > parseFloat(this.edititem.remainingamount)){
                this.$notify({
                    text:'Receive amount not greater than remaining amount',
                    type:'error',
                    duration:'8000',
                    speed:'800',
                })
            }
            else{
                this.submitfalse=true
                this.$store.commit('assignloadingstatus',1)
                this.form.loanemiid = this.emiitem.id
                this.form.submit(this,'post','api/loan/emi/receivepayment')
                    .then(response=>this.onSuccess(response))
                    .catch(error=>console.log(error));
            }
                
                
                 
        },
        onSuccess(data){

            this.$store.commit('assignloadingstatus',0)
            this.$store.commit('assignedititem',data)
            this.$parent.showpaymentform = false
            this.$notify({
                text:'Payment REC. Successfully',
                type:'success',
                duration:'8000',
                speed:'800',
            }),
            this.submitfalse=false
        },
        cancel(){
            this.$parent.showpaymentform = false
        }
    }
}
</script>