<template>
<div>
    <table class="table table-bordered table-font" style="margin-bottom:0;font-size:15px!important;">
        <thead style="color: #008787;">
            <tr>
                <th>S.No</th>
                <th>Message</th>
                <th>Followed By</th>
                <th>EMI Date</th>
                <th>OverDues Date</th>
                <th>Entry Date</th>
                <th>Entry By</th>
                <th>Approved by</th>
            </tr>
        </thead>
        <tbody v-for="(item,index) in commentlist" v-bind:key="item.id" style="color: red;">
            <tr >
                <td>{{ index+1 }}</td>
                <td>{{ item.comments }}</td>
                <td>{{ item.visitor!=null?item.visitor.name:'' }}</td>
                <td>{{ item.emi!=null?moment(item.emi.emidate).format("DD-MM-YYYY"):'' }}</td>
                <td>{{ moment(item.nextpaymentdate).format("DD-MM-YYYY") }}</td>
                <td>{{moment(item.created_at).format("DD-MM-YYYY")}} </td>
                <td>
                    {{ item.enteredby!=null?item.enteredby.name:'' }} <br> {{moment(item.created_at).format("hh:mm:s A")}}
                
                </td>
                <td>
                    <div v-if="item.approvestatus==1">
                        <span style="color:green">{{ item.customer.name }}</span>
                    </div>
                    <div v-else>
                        <span v-if="item.approvestatus==2" style="color:red"> Declined</span>
                        <span v-else style="color:black">Not Approved</span>
                    </div>
                </td>
                
            </tr>
        </tbody>
    </table>
    <div class="col-md-12" style="padding:0px;" v-if="emi.isactive==1">
        <div class="panel panel-cascade">
            <div class="panel-body" style="background-color:#82c1df8c">
                <div class="form-horizontal cascde-forms">
                    <div class="form-group row">
                        <label class="col-lg-4 col-md-3 control-label" style="color:#023d60;">
                        Enter Remark<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <textarea :disabled="checkfollowed()" class="form-control form-cascade-control input-small" v-model="form.comments" cols="20" id="CUSTHINT" name="CUSTHINT" rows="2" style="height:83px;"></textarea>
                            <span v-if="checkfollowed()" style="color:red">"Update Followed By First"</span>
                        </div>
                    </div>
                    <div class="form-group row" >
                        <label class="col-lg-4 col-md-3 control-label" style="color:#023d60;">Pay Date<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
                        <div class="col-lg-6 col-md-9">
                            <!-- <input :disabled="checkfollowed()" class="form-control form-cascade-control input-small"  v-model="form.nextpaymentdate"  type="date" required> -->
                            <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="form.nextpaymentdate" :min-date="currentdate" :max-date="fixdate">
                                <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <button type="button"
                                            class="date-picker" :disabled="checkfollowed()"
                                            @click="togglePopover()">
                                            <i class='bx bx-calendar'></i>
                                        </button>
                                        <input :value="inputValue" :disabled="checkfollowed()" @click="togglePopover()"
                                            class="form-control form-cascade-control input-small"
                                            placeholder="DD/MM/YYYY" readonly />
                                    </div>
                                </template>
                            </vc-date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group row ">
                    <div class="col-md-4"></div>
                   
                    <div class="col-md-3">
                        <button type="button" class="btn btn-primary" style="margin-top:0" @click="submit()">Submit</button>
                        <button type="button" class="btn btn-danger" @click="cancel()">Cancel</button>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Constants from '../utilities/Constants.vue';
// import Axios from 'axios';
export default {
    mixins:[Constants],
    props:['emiid','followed','emi','commentlist'],
    data(){
        return {
            form:new window.Form({
                id:0,comments:'',nextpaymentdate:'',emiid:0
            }),
            moment:moment,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            currentdate:new Date(),
            // fixdate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+15).toISOString().slice(0, 10),
            fixdate: '',
            approvalstatus:1,
        }
    },
    mounted(){
        this.initialize()
        // let param = {emiid:this.emiid}
        // this.$http.post('api/loan/emi/visitcomments/fetch',param)
        // .then((response) => this.processDetailResponse(response.data))
        // .catch((err) => {
        //     console.log('', err)
        // });
        // console.log("emi active :"+this.emi.isactive)
    },
    computed:{
        ...mapGetters([
		      'list','loggedinuser'
	    ]),
        
    },
    methods:{
        checkfollowed(){
            if(this.followed?.length === 0){
                return true
            }
        },
        initialize(){
            this.fixdate = new Date(this.emi?.emidate);
            if(this.loginusercheck(282)){
                this.fixdate.setDate(this.fixdate.getDate() + 2000);
            }else {
                this.fixdate.setDate(this.fixdate.getDate() + 15);
            }
        },
        loginusercheck(id){
			if(this.loggedinuser!=null && this.loggedinuser.role > 1){
				return true				
			}else if(this.loggedinuser!=null && this.loggedinuser.accessids!=null ){
				let access=this.loggedinuser.accessids.split(',');
				let found=access.find(element => element == id)
				if(typeof found!='undefined'){
					return true
				}else{
					return false
				}
			}
		},
        submit(){
            if(this.form.comments!='' && this.form.nextpaymentdate!=''){
                this.form.emiid = this.emiid
                this.form.submit(this,'post','api/loan/emi/visitcomments/createupdate')
                .then(response=>this.onSuccess(response))
                .catch(error=>console.log(error));
            }else{
                this.$notify({text:'Please Fill All the fields',type:'danger'})
            }
        },
        onSuccess(data){
            this.$notify({text:'Submitted successfully.',type:'success'})
            this.$store.commit('assignlist',data)
            this.$parent.visitcomments = false
            this.$parent.refresh();
        },
        cancel(){
            this.$parent.visitcomments = false
        },
        
        // hideDeclineRow(item){
        //     let hidedata = true
        //     if(item.approvestatus==2){
        //         hidedata = false
        //     }   
        //     else{
        //         hidedata = true
        //     }
        //     return hidedata;
                

        // }
        
    }
}
</script>